import ErrorBox from "../components/errorbox/ErrorBox";
import React from "react";
import {Link} from "react-router-dom";

export function handleError(error, callback) {
    return <ErrorBox text={errorText(error, callback, 'en')}/>
}

export function handleErrorGerman(error, callback) {
    return <ErrorBox text={errorText(error, callback, 'de')}/>
}

function errorText(error, callback, language) {
    if (language === 'de') {
        switch (error) {
            case "form-missing-fields":
                return <p>Bitte fülle alle Felder aus.</p>
            case "email-missing":
                return <p>Es konnte kein Konto mit dieser E-Mail-Adresse gefunden werden.</p>
            case "password-invalid":
                return <p>Falsches Passwort. Bitte versuche es erneut.</p>
            case "email-invalid":
                return <p>Die E-Mail-Adresse muss im richtigen Format sein.</p>
            case "same-email":
                return <p>Diese E-Mail-Adresse ist identisch mit deiner aktuellen E-Mail-Adresse.</p>
            case "email-not-verified":
                return <p>Die E-Mail-Adresse wurde noch nicht verifiziert. Bitte <button onClick={callback}>klicke hier</button>, um fortzufahren.</p>
            case "wrong-password":
                return <p>Das Passwort stimmt nicht.</p>
            case "username-too-long":
                return <p>Der Benutzername darf nicht länger als 20 Zeichen sein.</p>
            case "name-too-long":
                return <p>Der Name darf nicht länger als 50 Zeichen sein.</p>
            case "name-invalid":
                return <p>Der Name darf keine Sonderzeichen enthalten.</p>
            case "username-invalid":
                return <p>Der Benutzername darf nur alphanumerische Zeichen und Unterstriche enthalten.</p>
            case "id-invalid":
                return <p>Etwas ist schiefgelaufen. Bitte versuche es erneut und, falls das Problem nicht behoben wird, kontaktiere unseren Kundenservice.</p>
            case "rating-missing":
                return <p>Bitte gib deinem Feedback eine Bewertung.</p>
            case "password-short":
                return <p>Das Passwort muss mindestens 8 Zeichen lang sein.</p>
            case "username-exists":
                return <p>Dieser Benutzername ist bereits vergeben.</p>
            case "code-invalid":
            case "invalid-code":
                return <p>Der Bestätigungscode ist falsch.</p>
            case "expired-code":
                return <p>Der Bestätigungscode ist abgelaufen. Klicke auf „Code erneut senden“, um einen neuen Code zu erhalten.</p>
            case "missing-device":
                return <p>Bitte wähle dein Betriebssystem und Gerät aus.</p>
            case "input-none":
                return <p className="onboarding-dialogBox-container-text-error">
                    Du musst mindestens ein Betriebssystem und ein Gerät auswählen.
                </p>
            case "email-exists":
                return <p>
                    Dieses E-Mail-Konto existiert bereits. Bitte versuche, dich <span><Link to="/login">einzuloggen.</Link></span>
                </p>
            case "accept-terms-missing":
                return <p>Bitte akzeptiere unsere Nutzungsbedingungen und Datenschutzrichtlinien.</p>
            case "passwords-do-not-match":
                return <p>Die Passwörter stimmen nicht überein.</p>
            case "please-wait":
                return <p>Bitte warte eine Minute, bevor du auf 'Erneut senden' klickst. Überprüfe deinen Posteingang und Spam-Ordner.</p>
            case "network-error":
                return <p>Es konnte keine Verbindung zum Server hergestellt werden. Überprüfe deine Internetverbindung oder versuche es später erneut.</p>
            case "apple-subscription-active":
                return <p>Ein Abonnement im Apple Store ist bereits aktiv.</p>
            case "google-subscription-active":
                return <p>Ein Abonnement im Play Store ist bereits aktiv.</p>
            case "issuer-invalid":
            case "audience-invalid":
            case "token-expired":
            case "code-hash-invalid":
            case "not-logged-in":
            case "invalid-credentials":
            case "invalid-device":
            case "invalid-device-model":
            case "invalid-feedback":
            case "invalid-app-version":
            case "empty-promocode":
                return <p>Bitte gib den 6- oder 8-stelligen Aktionscode ein.</p>
            case "promo-code-invalid":
                return <p>Der von dir eingegebene Aktionscode ist ungültig.</p>
            case "promo-code-already-subscriber":
                return <p>Du bist bereits Abonnent von Beatlii.</p>
            case "already-used-promo-code":
                return <p>Du hast bereits einen Aktionscode verwendet.</p>
            default:
                return <p>Etwas ist schiefgelaufen, bitte versuche es später erneut.</p>
        }
    } else {
        switch (error) {
            case "form-missing-fields":
                return <p>Please fill in all fields.</p>
            case "email-missing":
                return <p>Can't find an account with this email address.</p>
            case "password-invalid":
                return <p>Incorrect password. Please try again.</p>
            case "email-invalid":
                return <p>Email address needs to be in the right format.</p>
            case "same-email":
                return <p>This email address is the same as your current email address.</p>
            case "email-not-verified":
                return <p>Email address has not been verified. Please <button onClick={callback}>click
                    here</button> to continue.</p>
            case "wrong-password":
                return <p>Password does not match.</p>
            case "username-too-long":
                return <p>Username can't be longer than 20 characters.</p>
            case "name-too-long":
                return <p>Name can't be longer than 50 characters.</p>
            case "name-invalid":
                return <p>Name can't contain special characters.</p>
            case "username-invalid":
                return <p>Username can only contain alphanumeric characters and underscores.</p>
            case "id-invalid":
                return <p>Something went wrong. Please try again and, if the error is not fixed, contact our customer
                    support.</p>
            case "rating-missing":
                return <p>Please give your feedback a rating.</p>
            case "password-short":
                return <p>Password needs to be 8 characters or more.</p>
            case "username-exists":
                return <p>That username is already taken.</p>
            case "code-invalid":
            case "invalid-code":
                return <p>Wrong verification code.</p>
            case "expired-code":
                return <p>Verification code has expired. Click Resend Code to receive a new code.</p>
            case "missing-device":
                return <p>Please select your operating system and device.</p>
            case "input-none":
                return <p className="onboarding-dialogBox-container-text-error">
                    You must submit at least one operating system and one device.
                </p>
            case "email-exists":
                return <p>
                    This email account already exists. Please try to <span><Link to="/login"> log in.</Link></span>
                </p>
            case "accept-terms-missing":
                return <p>Please accept our Terms of Use and Privacy Policy.</p>
            case "passwords-do-not-match":
                return <p>Passwords don't match.</p>
            case "please-wait":
                return <p>Please wait a minute before clicking resend. <br/> Check your inbox and spam folder.</p>
            case "network-error":
                return <p>Can't connect to the server, check your internet connection or try again later.</p>
            case "apple-subscription-active":
                return <p>A subscription in the Apple store is already active.</p>
            case "google-subscription-active":
                return <p>A subscription in the Play store is already active.</p>
            case "issuer-invalid":
            case "audience-invalid":
            case "token-expired":
            case "code-hash-invalid":
            case "not-logged-in":
            case "invalid-credentials":
            case "invalid-device":
            case "invalid-device-model":
            case "invalid-feedback":
            case "invalid-app-version":
            case "empty-promocode":
                return <p>Please enter the 6 or 8 digit promo code.</p>
            case "promo-code-invalid":
                return <p>The promo code you entered is invalid.</p>
            case "promo-code-already-subscriber":
                return <p>You are already a subscriber to Beatlii.</p>
            case "already-used-promo-code":
                return <p>You already used a promo code.</p>
            default:
                return <p>Something went wrong, please try again later.</p>
        }
    }
}