import './subscriptionscreen.scss'
import {Link} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import {userRequests} from "../../../api/userRequests";
import SuccessBox from "../../../components/successbox/SuccessBox";
import {handleError} from "../../../api/errorHandling";
import {doAuthenticatedRequest} from "../../../api/requests";
import NoSubscription from "../subscription/subcription-states/NoSubscription";
import SubscriptionActive from "../subscription/subcription-states/SubscriptionActive";
import SubscriptionBackendTrial from "../subscription/subcription-states/SubscriptionBackendTrial";


class SubscriptionScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            successBox: false,
            errorCode: null
        }

        this.disableSuccessBox = this.disableSuccessBox.bind(this);
        this.subscriptionActive = this.subscriptionActive.bind(this);
    }

    disableSuccessBox() {
        this.setState({successBox: false})
    }

    componentDidMount() {
        document.title = "Account - Subscription";
    }

    subscriptionActive() {
        const {user} = this.props;
        const now = new Date().getTime() / 1000;
        const subscriptionActiveUntil = parseInt(user.subscriptionActiveUntil);
        return subscriptionActiveUntil - now > 0;
    }

    render() {
        const {user} = this.props;
        const {successBox, errorCode} = this.state;
        let renderSuccess;
        let renderError;
        let renderPage;

        if (successBox) {
            renderSuccess = <SuccessBox text={<p>Subscription activated.</p>} setSuccessStatus={this.disableSuccessBox}/>
        }

        if (errorCode !== null) {
            renderError = handleError(errorCode, null);
        }

        if (this.subscriptionActive()) {
            if (user.nextPaymentAmount == null) {
                renderPage = <SubscriptionBackendTrial user={user} />
            } else {
                renderPage = <SubscriptionActive user={user} />
            }
        } else {
            renderPage = <NoSubscription user={user} />
        }

        return <>
            <div className="SubscriptionScreen-container">
                <div className="SubscriptionScreen-mainContent">
                    {renderSuccess}
                    {renderError}
                    {renderPage}
                </div>
            </div>
        </>
    }

}

export default SubscriptionScreen;
