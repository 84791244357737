class EmailTypoChecker {
  static commonDomains = [
    "gmail.com",
        "yahoo.com",
        "hotmail.com",
        "aol.com",
        "hotmail.co.uk",
        "hotmail.fr",
        "msn.com",
        "yahoo.fr",
        "wanadoo.fr",
        "orange.fr",
        "comcast.net",
        "yahoo.co.uk",
        "yahoo.com.br",
        "yahoo.co.in",
        "live.com",
        "rediffmail.com",
        "free.fr",
        "gmx.de",
        "web.de",
        "ymail.com",
        "yandex.ru",
        "libero.it",
        "outlook.com",
        "uol.com.br",
        "bol.com.br",
        "mail.ru",
        "cox.net",
        "hotmail.it",
        "sbcglobal.net",
        "sfr.fr",
        "live.fr",
        "verizon.net",
        "live.co.uk",
        "googlemail.com",
        "yahoo.es",
        "ig.com.br",
        "live.nl",
        "bigpond.com",
        "terra.com.br",
        "yahoo.it",
        "neuf.fr",
        "yahoo.de",
        "alice.it",
        "rocketmail.com",
        "att.net",
        "laposte.net",
        "facebook.com",
        "bellsouth.net",
        "yahoo.in",
        "hotmail.es",
        "charter.net",
        "yahoo.ca",
        "yahoo.com.au",
        "rambler.ru",
        "hotmail.de",
        "tiscali.it",
        "shaw.ca",
        "yahoo.co.jp",
        "sky.com",
        "earthlink.net",
        "optonline.net",
        "freenet.de",
        "t-online.de",
        "aliceadsl.fr",
        "virgilio.it",
        "home.nl",
        "qq.com",
        "telenet.be",
        "me.com",
        "yahoo.com.ar",
        "tiscali.co.uk",
        "yahoo.com.mx",
        "voila.fr",
        "gmx.net",
        "mail.com",
        "planet.nl",
        "tin.it",
        "live.it",
        "ntlworld.com",
        "arcor.de",
        "yahoo.co.id",
        "frontiernet.net",
        "hetnet.nl",
        "live.com.au",
        "yahoo.com.sg",
        "zonnet.nl",
        "club-internet.fr",
        "juno.com",
        "optusnet.com.au",
        "blueyonder.co.uk",
        "bluewin.ch",
        "skynet.be",
        "sympatico.ca",
        "windstream.net",
        "mac.com",
        "centurytel.net",
        "chello.nl",
        "live.ca",
        "aim.com",
        "bigpond.net.au",
    // Add more domains from your Flutter implementation as needed
  ];

  // Check if two strings are transpositions of adjacent characters
  static isTransposition(str1, str2) {
    if (str1.length !== str2.length || str1.length < 2) return false;

    const differences = [];
    for (let i = 0; i < str1.length; i++) {
      if (str1[i] !== str2[i]) {
        differences.push(i);
      }
      if (differences.length > 2) return false;
    }

    if (differences.length !== 2) return false;

    return (
      differences[1] - differences[0] === 1 &&
      str1[differences[0]] === str2[differences[1]] &&
      str1[differences[1]] === str2[differences[0]]
    );
  }

  // Calculate Levenshtein distance between two strings
  static calculateLevenshteinDistance(str1, str2) {
    const len1 = str1.length;
    const len2 = str2.length;

    const matrix = Array(len1 + 1)
      .fill()
      .map(() => Array(len2 + 1).fill(0));

    for (let i = 0; i <= len1; i++) {
      matrix[i][0] = i;
    }
    for (let j = 0; j <= len2; j++) {
      matrix[0][j] = j;
    }

    for (let i = 1; i <= len1; i++) {
      for (let j = 1; j <= len2; j++) {
        const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost
        );
      }
    }

    return matrix[len1][len2];
  }

  static getSuggestion(email) {
    if (!email.includes('@')) return null;

    const parts = email.split('@');
    if (parts.length !== 2) return null;

    const inputDomain = parts[1].toLowerCase();

    // Only proceed if the input domain is at least 3 characters
    if (inputDomain.length < 3) return null;

    var result;
    for (const domain of this.commonDomains) {
      // Skip if the domain is exactly the same
      if (inputDomain === domain) return null;

      if (result != null) continue;

      // Allow for more length difference
      if (Math.abs(domain.length - inputDomain.length) > 2) continue;

      // Check for transposition errors first
      if (this.isTransposition(inputDomain, domain)) {
        result = `${parts[0]}@${domain}`;
      }

      // Check Levenshtein distance - allow distance of 2 for longer domains
      const distance = this.calculateLevenshteinDistance(domain, inputDomain);
      if ((distance <= 2 && domain.length >= 8) || distance === 1) {
        result = `${parts[0]}@${domain}`;
      }
    }

    return result;
  }
}

export default EmailTypoChecker;