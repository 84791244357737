import React from 'react';
import Header from "../../../../components/header/Header";
import SideNavbar from "../../../../components/side-navbar/SideNavbar";
import './editprofilescreen.scss'
import {useState, useEffect} from "react";
import {userRequests} from "../../../../api/userRequests";
import {EditProfileComponent} from "../../../../components/edit-profile/EditProfileComponent";
import {ChangeEmailComponent} from "../../../../components/edit-profile/ChangeEmailComponent";
import {ChangePasswordComponent} from "../../../../components/edit-profile/ChangePasswordComponent";

function EditProfileScreen() {
    const [user, setUser] = useState({name: "", username: ""});
    const [emailModel, setEmailModel] = useState({previousEmail: "", email: ""});
    const [disabledStatus, setDisabledStatus] = useState(false);
    const [currentUsername, setCurrentUsername] = useState("");

    useEffect(() => {
        document.title = "Settings - Edit Profile";
        userRequests(result => {
            setCurrentUsername(result.username);
            setUser({name: result.name, username: result.username});
            setEmailModel({previousEmail: result.email})
        });
    }, [setUser, setEmailModel, setDisabledStatus]);

    return <>
        <div className="EditProfileScreen-container">
            <div className="EditProfileScreen-mainContent">
                <EditProfileComponent currentUsername={currentUsername} setCurrentUsername={setCurrentUsername}
                                      user={user} setUser={setUser}/>
                <hr/>
                <ChangeEmailComponent setDisabledStatus={setDisabledStatus} disabledStatus={disabledStatus}
                                      emailModel={emailModel} setEmailModel={setEmailModel} />
                <hr/>
                <ChangePasswordComponent setDisabledStatus={setDisabledStatus} email={emailModel.email} />
            </div>
        </div>
    </>
}

export default EditProfileScreen;
