import './registerscreen.scss';
import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {AiOutlineInfoCircle, AiOutlineCloseCircle, AiOutlineCheckCircle,} from "react-icons/ai";

import ReactGA from "react-ga4";

import {doRequest}                                                      from "../../../api/requests";
import {handleError}                                                    from "../../../api/errorHandling";
import FormInput                                                        from "../../../components/forminput/FormInput";
import {emailValidation, nameValidation, passwordValidation, isValid}   from "../../../components/forminput/validation";
import phonesPicture                                                    from "../../../assets/images/HeroImage-App.png";
import phonesPictureWebP                                                from "../../../assets/images/HeroImage-App.webp";
import {ReactComponent as FreedrumLogo}                                 from "../../../assets/images/freedrum-logo.svg";

import VerifyEmailModal from "./VerifyEmailModal";

function RegisterScreenEmail({callback}) {

    const [user, setUser] = useState({name: '', email: '', password: ''});
    const [validation, setValidation] = useState({name: false, email: false, password: false, conditions: false})
    const [hasFocussedField, setHasFocussedField] = useState({
        name: false,
        email: false,
        password: false,
        conditions: false
    })

    const [modalIsOpen, setIsOpen] = useState(false);

    const [errorCode, setErrorCode] = useState(null);
    let renderError;

    useEffect( () => {
        document.title = "Register";
    })

    // Function to send data to backend and activate modal when response is success.
    const registerHandler = (e) => {
        e.preventDefault();

        setHasFocussedField({...hasFocussedField, email: true, password: true});

        if (!isValid(validation)) {
            if (!validation.email) {
                setErrorCode("email-address-invalid");
            } else if (!validation.password) {
                setErrorCode("password-short");
            } else if (!validation.conditions) {
                setErrorCode("accept-terms-missing");
            } else {
                setErrorCode("form-missing-fields");
            }
            return;
        }

        setErrorCode(null);

        doRequest("POST", "/security/register", {email: user.email, password: user.password, name: user.name})
            .then(result => {
                ReactGA.event({
                    category: 'Account',
                    action: 'Created account',
                });
                if (!result.data.verified) {
                    user.email = result.data.email;
                    setIsOpen(true);
                }
            })
            .catch(error => setErrorCode(error));
    };

    if (errorCode) {
        renderError = handleError(errorCode, null);
    }

    const handleTermsOfService = (input) => {
        setValidation({...validation, [input.target.name]: !validation.conditions});
    }

    // User input handling - Show error when they input wrong email format, password less than 8 char, or empty name. CTA button also being disable.
    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true});

        if (inputName === 'name') {
            setUser({...user, [inputName]: inputValue});
            if (nameValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
        }

        if (inputName === 'email') {
            setUser({...user, [inputName]: inputValue});
            if (emailValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
        }

        if (inputName === 'password') {
            setUser({...user, [inputName]: inputValue});
            if (passwordValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
        }
    };

    return <>
        <div className="RegisterScreen-container">

            <div className="RegisterScreen-beatliiLogoContainer">
                <FreedrumLogo/>
            </div>
            <div className="RegisterScreen-container__section">
                <div className="RegisterScreen-formContainer">
                    <h1 className="RegisterScreen-formContainer-title">Create an account to get started and manage your Beatlii membership.</h1>
                    <form className="RegisterScreen-form" autoComplete="off" onSubmit={registerHandler}>
                        {renderError}
                        <FormInput name='name' handleFormChange={handleFormChange} title="Full name"
                                   placeholder="Your full name" type="text"
                                   valid={validation.name || !hasFocussedField.name} value={""}/>
                        <FormInput name='email' handleFormChange={handleFormChange} title="Email"
                                   placeholder={"youremail@domain.com"} type="email"
                                   valid={validation.email || !hasFocussedField.email}
                                   value={""}/>
                        <div className="RegisterScreen-textBelowInputs-container">
                            <AiOutlineInfoCircle className="RegisterScreen-infoIcon"/>
                            <p className="RegisterScreen-textBelowInputs">Please use the email address that’s associated
                                to your Apple ID or Google Play account.</p>
                        </div>
                        <FormInput name='password' handleFormChange={handleFormChange} title="Password"
                                   placeholder="Password" type="password"
                                   valid={validation.password || !hasFocussedField.password} value={""}/>
                        <p className="RegisterScreen-textBelowInputs password">{validation.password ?
                            <AiOutlineCheckCircle className="RegisterScreen-wrongPasswordIcon" color="green"/> :
                            <AiOutlineCloseCircle className="RegisterScreen-wrongPasswordIcon"/>
                        }
                            Minimum 8 characters.
                        </p>
                        <div className="RegisterScreen-termsAndConditions">
                            <label className="RegisterScreen-termsAndConditions__label">
                                <input type="checkbox"
                                       name="conditions"
                                       checked={validation.conditions}
                                       onChange={handleTermsOfService}
                                       id="terms_checkbox"
                                />
                                <div className="checkbox-custom"/>
                                <p htmlFor="terms_checkbox">
                                    By signing up, you're agreeing to our
                                    <span className="RegisterScreen-termsAndConditions__textLink">
                                        <Link to={{pathname: "https://beatlii.com/pages/terms-and-conditions"}}
                                              target="_blank"> Terms of Use</Link></span> and
                                    <span className="RegisterScreen-termsAndConditions__textLink"><Link
                                        to={{pathname: "https://beatlii.com/pages/privacy-notice"}} target="_blank"> Privacy Policy</Link></span>.
                                </p>

                            </label>
                        </div>
                        <button type="submit" className="RegisterScreen-registerButton" id="register-button">
                            Register
                        </button>
                    </form>
                    <p className="RegisterScreen-askLogin">Have an account?
                        <span className="RegisterScreen-askLoginButton"
                        > <Link to="/login"> Login</Link></span>
                    </p>
                </div>
                <div className="RegisterScreen-phonesPictureContainer email">
                    <picture>
                        <source className="RegisterScreen-phonesPictureContainer__image"
                                srcSet={phonesPictureWebP}
                                type="image/webp"/>
                        <img className="RegisterScreen-phonesPictureContainer__image"
                             src={phonesPicture} alt=""/>
                    </picture>
                </div>
            </div>
            {modalIsOpen && <VerifyEmailModal email={user.email} callback={callback} />}

        </div>
    </>
}


export default RegisterScreenEmail;
