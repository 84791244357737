import './loginscreenfrominfo.scss';

import {Link, Navigate} from "react-router-dom";
import {useState, useRef, useEffect} from "react";

import FormInput                                        from "../../../components/forminput/FormInput";
import ForgotPasswordModal                              from "../../../components/forgot-password/ForgotPasswordModal";
import {emailValidation, passwordValidation, isValid}   from "../../../components/forminput/validation";
import {doRequest}                                      from "../../../api/requests";
import {saveCredentials}                                from "../../../api/security";
import {handleError}                                    from "../../../api/errorHandling";
import {ReactComponent as FreedrumLogo}                 from "../../../assets/images/freedrum-logo.svg";

import VerifyEmailModal                                 from '../registerscreen/VerifyEmailModal';

const LoginScreenFromInfo = ({callback}) => {

    const [user, setUser] = useState({email: '', password: ''});
    const [hasFocussedField, setHasFocussedField] = useState({email: false, password: false})
    const [validation, setValidation] = useState({email: false, password: false})
    const blurElement = useRef(null);

    const [errorCode, setErrorCode] = useState(null);
    const [Navigate, setNavigate] = useState(false);

    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
    const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
    const [disabledStatus, setDisabledStatus] = useState(false);

    let renderError;

    useEffect(() => {
        document.title = "Beatlii - Account Login";
        if (!isForgotPasswordModalOpen && !isVerifyEmailModalOpen) {
            setDisabledStatus(false);
        }
    }, [isForgotPasswordModalOpen, isVerifyEmailModalOpen, setDisabledStatus])

    const loginHandler = (e) => {
        e.preventDefault();

        if (disabledStatus) { return; }

        setHasFocussedField({...hasFocussedField, email: true, password: true});

        if (!isValid(validation)) {
            if (!validation.email) {
                setErrorCode("email-invalid");
            } else if (!validation.password) {
                setErrorCode("password-short");
            } else {
                setErrorCode("form-missing-fields");
            }
            return;
        }

        setErrorCode(null);

        doRequest("POST", "/security/signin", {email: user.email, password: user.password})
            .then(result => {
                if (result !== undefined) {
                    saveCredentials(result.data, () => {
                        setNavigate(true)
                    });
                    callback();
                }
            })
            .catch(error => setErrorCode(error));
    }

    if (errorCode != null) {
        renderError = handleError(errorCode, () => {
            setDisabledStatus(true);
            setIsVerifyEmailModalOpen(true);
        })
    }

    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true});

        if (inputName === 'email') {
            setUser({...user, [inputName]: inputValue});
            if (emailValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }
        if (inputName === 'password') {
            setUser({...user, [inputName]: inputValue});
            if (passwordValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        setUser({...user, [inputName]: inputValue});
    }

    const closeModalClicked = () => {
        setDisabledStatus(true)
        setIsForgotPasswordModalOpen(false);
        setIsVerifyEmailModalOpen(false);
    }

    return <>
        <div className="LoginScreenUpdate-container">
            <div className="LoginScreenUpdate-TopArea">
                <div className="LoginScreenUpdate-TopArea__heroimage"></div>
                <div className="LoginScreenUpdate-TopArea__header">
                    <div className="LoginScreenUpdate-TopArea__header-beatliiLogoContainer">
                        <a href="https://beatlii.com"><FreedrumLogo/></a>
                    </div>
                    <div className="LoginScreenUpdate-TopArea__header-logIn">
                       
                    </div>
                </div>
                <div className="LoginScreenUpdate-TopArea__FormContainer">
                    <div className="LoginScreenUpdate-TopArea__FormContainer-box">
                        <h1 className="LoginScreenUpdate-TopArea__FormContainer-box-title">Log in</h1>
                        <h3 className="LoginScreenUpdate-TopArea__FormContainer-box-SmallTitle">New to Beatlii? <span><Link className="LoginScreenUpdate-TopArea__FormContainer-box-SmallTitle span" to="/register">Register here.</Link></span></h3>
                        <div className="Login__containerBox-formBox">
                            <form ref={blurElement} className="LoginScreen-form" autoComplete="off" onSubmit={loginHandler}>
                                {renderError}
                                <FormInput handleFormChange={handleFormChange} name='email' title="Email"
                                    placeholder="youremail@domain.com" type="email"
                                    valid={validation.email || !hasFocussedField.email}
                                    disabledStatus={disabledStatus} value={""}
                                    backgroundColor={"#f8f8f8"}/>
                                <FormInput handleFormChange={handleFormChange} name='password' title="Password"
                                    placeholder="Password" type="password"
                                    valid={validation.password || !hasFocussedField.password}
                                    disabledStatus={disabledStatus} value={""}
                                    backgroundColor={"#f8f8f8"}/>
                                <span className="LoginScreen-textBelowInputs" onClick={() => {
                                    setDisabledStatus(true);
                                    setIsForgotPasswordModalOpen(true);
                                }}>
                                    Forgot your password?
                                </span>
                                <button type="submit" className="LoginScreen-registerButton" id="login-button" disabled={disabledStatus ? "disabled" : null}>
                                        Log in
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {isForgotPasswordModalOpen && <ForgotPasswordModal closeModalClicked={closeModalClicked}/>}
            {isVerifyEmailModalOpen && <VerifyEmailModal closeModalClicked={closeModalClicked} email={user.email} />}
        </div>
    </>

};

export default LoginScreenFromInfo;