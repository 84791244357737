import React, {useState, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import './deleteaccountmodal.scss';
import PinField from 'react-pin-field';
import FocusTrap from "focus-trap-react";
import {MdClose} from "react-icons/md";
import {Navigate} from "react-router-dom";
import {doAuthenticatedRequest, doRequest} from "../../../../api/requests";
import {handleError} from "../../../../api/errorHandling";
import SuccessBox from "../../../../components/successbox/SuccessBox";

const DeleteAccountModal = ({vid, openModal, setDisabledStatus, email}) => {
    const focusRef = useRef(null);
    const [secretCode, setSecretCode] = useState("");
    const [accountDeleted, setAccountDeleted] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const [successStatus, setSuccessStatus] = useState(false);
    let renderError;
    let renderSuccess;

    useEffect(() => {
        let currentElement = focusRef.current[0];
        if (currentElement !== undefined) {
            currentElement.focus();
        }
    }, [focusRef]);

    const secretCodeHandler = (code) => {
        setSecretCode(code);
    };

    const deleteAccountClicked = (e) => {
        e.preventDefault();

        if (secretCode.length !== 6) {
            setErrorCode("form-missing-fields");
            return;
        }

        doAuthenticatedRequest("POST", "/security/delete-account-step2", {'vid': vid, 'code': secretCode})
            .then(() => setAccountDeleted(true))
            .catch((error) => setErrorCode(error))
    }

    const resendCode = (vid) => {
        doRequest("POST", "/security/resend-verification", {vid: vid})
            .then(() => {
                setErrorCode(null);
                setSuccessStatus(true);
            })
            .catch((error) => {
                setErrorCode(error);
                setSuccessStatus(false);
            });
    }

    const closeModalClicked = () => {
        setDisabledStatus(false);
        openModal(false);
    }

    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    if (successStatus) {
        renderSuccess = <SuccessBox text={<p>Code has been sent again.</p>} setSuccessStatus={setSuccessStatus}/>
    }

    if (accountDeleted) {
        return <Navigate to="/delete-success"/>
    }

    let renderModal = <FocusTrap>
        <div className="ReactModal__Content" role="dialog" aria-modal="true">
            <div className="DeleteAccountModal-container">
                <div className="DeleteAccountModal-container-closeButton">
                    <button className="DeleteAccountModal-container-closeButton-image" onClick={closeModalClicked}>
                        <MdClose className="DeleteAccountModal-container-closeButton-image-icon"/>
                    </button>
                </div>
                <h1 className="DeleteAccountModal-title">Delete Account</h1>
                <p className="DeleteAccountModal-body">
                    To confirm this change, please enter the 6-digit verification code we sent to <span
                    className="DeleteAccountModal-email">{email}</span>.
                </p>
                <p className="DeleteAccountModal-body">
                    Did not receive the email? Please check your spam folder or resend verification code.
                </p>
                {renderError}
                {renderSuccess}
                <div ref={focusRef} tabIndex="-1" className="DeleteAccountModal-code-container">
                    <PinField length={6} className="DeleteAccountModal-code" onChange={secretCodeHandler}>
                    </PinField>
                </div>
                <button onClick={deleteAccountClicked} type="button" className="DeleteAccountModal-verifyButton">
                    Delete account
                </button>
                <button onClick={() => resendCode(vid)} type="button"
                        className="DeleteAccountModal-resendCodeButton">
                    Resend code
                </button>
            </div>
        </div>
    </FocusTrap>

    return ReactDOM.createPortal(
        <div className="modal-container">
            {renderModal}
        </div>, document.body
    );
};

export default DeleteAccountModal;
