import React, {useState, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import './changepasswordmodal.scss';
import PinField from 'react-pin-field';
import FocusTrap from "focus-trap-react";
import {MdClose} from "react-icons/md";
import {doAuthenticatedRequest, doRequest} from '../../../../api/requests';
import {handleError} from "../../../../api/errorHandling";
import {logOut} from "../../../../api/userRequests";
import {Navigate} from "react-router-dom";
import SuccessBox from "../../../../components/successbox/SuccessBox";

const ChangePasswordModal = ({openModal, modalName, setModalName, setDisabledStatus, email, vid}) => {
    const focusRef = useRef(null);
    const [secretCode, setSecretCode] = useState("");
    const [errorCode, setErrorCode] = useState(null);
    const [backToLogin, setBackToLogin] = useState(false);
    const [successStatus, setSuccessStatus] = useState(false);
    let renderSuccess;
    let renderError;
    let renderModal;

    useEffect(() => {
        let currentElement = focusRef.current[0];
        if (currentElement !== undefined) {
            currentElement.focus();
        }
    }, [focusRef]);

    const secretCodeHandler = (code) => {
        setSecretCode(code);
        setErrorCode(null);
    };

    if (backToLogin) {
        return <Navigate to="/login"/>
    }

    const verificationHandler = (e) => {
        e.preventDefault();

        if (secretCode.length !== 6) {
            setErrorCode("form-missing-fields");
            return;
        }

        doAuthenticatedRequest("POST", "/security/change-password-step2", {vid: vid, code: secretCode})
            .then(() => {
                setModalName("success");
            })
            .catch(error => setErrorCode(error));
    }

    const closeModalClicked = () => {
        setDisabledStatus(false);
        openModal(false);
    }

    const logoutClicked = () => {
        logOut(() => {
            setBackToLogin(true);
        });
    }

    if (successStatus) {
        renderSuccess = <SuccessBox text={<p>Code has been sent again.</p>} setSuccessStatus={setSuccessStatus}/>
    }

    const resendCode = (vid) => {
        doRequest("POST", "/security/resend-verification", {vid: vid})
            .then(() => {
                setErrorCode(null);
                setSuccessStatus(true);
            })
            .catch((error) => {
                setErrorCode(error);
                setSuccessStatus(false);
            });
    }

    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    if (modalName === "verify") {
        renderModal = <div className="ReactModal__Content" role="dialog" aria-modal="true">
            <div className="ChangeEmailScreen-container">
                <div className="ChangeEmailScreen-container-closeButton">
                    <button className="ChangeEmailScreen-container-closeButton-image" onClick={closeModalClicked}>
                        <MdClose className="ChangeEmailScreen-container-closeButton-image-icon"/>
                    </button>
                </div>
                <h1 className="ChangeEmailScreen-title">Change Password</h1>
                <p className="ChangeEmailScreen-body">
                    To confirm this change, please enter the 6-digit verification code we sent to <span
                    className="ChangeEmailScreen-email">{email}</span>
                </p>
                <p className="ChangeEmailScreen-body">
                    Did not receive the email? Please check your spam folder or resend verification code.</p>
                {renderError}
                {renderSuccess}
                <div tabIndex="-1" className="ChangeEmailScreen-code-container">
                    <PinField ref={focusRef} length={6} className="ChangeEmailScreen-code" onChange={secretCodeHandler}>
                    </PinField>
                </div>
                <button onClick={verificationHandler} type="button" className="ChangeEmailScreen-verifyButton">
                    Verify
                </button>
                <button type="button" className="ChangeEmailScreen-resendCodeButton"
                        onClick={() => resendCode(vid)}>Resend code
                </button>
            </div>
        </div>
    } else if (modalName === "success") {
        renderModal = <div className="ReactModal__Content" role="dialog" aria-modal="true">
            <div ref={focusRef} tabIndex="-1" className="ChangeEmailScreen-container">
                <h1 className="ChangeEmailScreen-title">Password Saved</h1>
                <p className="ChangeEmailScreen-body" style={{marginBottom: "8px", textAlign: "center"}}>
                    Your new password has been saved. You will need to re-login to access your account.
                </p>
                <button onClick={logoutClicked} type="button" className="ChangeEmailScreen-CloseButton">
                    Back to log in
                </button>
            </div>
        </div>
    }

    return ReactDOM.createPortal(
        <FocusTrap>
            <div className="modal-container">
                {renderModal}
            </div>
        </FocusTrap>, document.body
    );
};

export default ChangePasswordModal;
