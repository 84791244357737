import './forminput.scss'
import {useState, useEffect} from "react";
import EmailTypoChecker from '../../utils/EmailTypoChecker';

function FormInput({title, placeholder, type, handleFormChange, name, valid, backgroundColor, focusRef, disabledStatus, value}) {

    const [inputValue, setInputValue] = useState("");
    const [emailSuggestion, setEmailSuggestion] = useState(null);
    const [lastEmailCheck, setLastEmailCheck] = useState("");

    useEffect( () => {
        if (value !== undefined) {
            setInputValue(value)
        }
    }, [value])

    useEffect(() => {
        // Only run this effect for email inputs and when lastEmailCheck changes
        if (type === 'email' && lastEmailCheck) {
            // Use a reference to the timeout so we can clear it if needed
            const timeoutId = setTimeout(() => {
                const suggestion = EmailTypoChecker.getSuggestion(lastEmailCheck);
                setEmailSuggestion(suggestion);
            }, 1000);

            // Clean up timeout on component unmount or when lastEmailCheck changes
            return () => clearTimeout(timeoutId);
        }
    }, [lastEmailCheck, type]);

    const handleInputChange = (e) => {
        const currentValue = e.target.value;
        setInputValue(currentValue);
        handleFormChange(e.target);

        // For email inputs, update lastEmailCheck to trigger the useEffect
        if (type === 'email') {
            // Clear any existing suggestions immediately when typing
            setEmailSuggestion(null);
            setLastEmailCheck(currentValue);
        }
    };

    const applySuggestion = () => {
        if (emailSuggestion) {
            setInputValue(emailSuggestion);
            const fakeEvent = {
                target: {
                    name: name,
                    value: emailSuggestion
                }
            };
            handleFormChange(fakeEvent.target);
            setEmailSuggestion(null);
        }
    };

    return (
        <>
            <div ref={focusRef} className="FormInput-nameField"
                 style={{backgroundColor:backgroundColor, border: valid ? '' : '1px solid #ff1744'}}>
                <label className="FormInput-nameLabel"
                       htmlFor="register-name"
                       style= {{backgroundColor:backgroundColor}}>
                    {title}
                </label>
                {type === 'textarea'
                    ? <textarea
                        name={name}
                        value={inputValue}
                        onChange={handleInputChange}
                        title={title}
                        placeholder={placeholder}
                        className="FormInput-nameInput-textarea"
                        disabled={disabledStatus ? "disabled" : null}/>
                    : <input
                        name={name}
                        onChange={handleInputChange}
                        value={inputValue}
                        className="FormInput-nameInput"
                        type={type}
                        title={title}
                        placeholder={placeholder}
                        disabled={disabledStatus ? "disabled" : null}/>}
            </div>

            {emailSuggestion && (
                <div className="email-suggestion">
                    Did you mean <span
                        onClick={applySuggestion}
                        className="email-suggestion-link"
                    >{emailSuggestion}</span>?
                </div>
            )}
        </>
    );
}

export default FormInput;