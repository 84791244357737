import "./redeemscreenamazon.scss";
import {Link} from "react-router-dom";
import {useState, useEffect} from "react";
import {AiOutlineInfoCircle, AiOutlineCloseCircle, AiOutlineCheckCircle,} from "react-icons/ai";
import {Navigate} from "react-router";
import ReactGA from "react-ga4";

import {doAuthenticatedRequest, updateAccessToken} from "../../../api/requests.js";
import {handleError} from "../../../api/errorHandling";
import FormInput from "../../../components/forminput/FormInput";
import {emailValidation, nameValidation, passwordValidation, promoCodeValidation, isValid} from "../../../components/forminput/validation";
import {ReactComponent as FreedrumStudioLogo} from "../../../assets/images/Logo_FD_Studio.svg";
import {ReactComponent as AmazonLogo} from "../../../assets/images/AmazonLogo.svg";

import VerifyEmailModal from "./VerifyEmailModal";

const RedeemScreenAmazon = () => {

    const [user, setUser] = useState({promocode: ''});
    const [validation, setValidation] = useState({promocode: false})
    const [hasFocussedField, setHasFocussedField] = useState({
        promocode: false,
    });
    const [promotionActive, setPromotionActive] = useState(false)

    const [modalIsOpen, setIsOpen] = useState(false);

    const [errorCode, setErrorCode] = useState(null);
    const [Navigate, setNavigate] = useState(false);

    let renderError;

    useEffect(()=> {
        document.title = "Beatlii - Redeem code";
    });

    // Function to send data to backend and activate modal when response is success.
    const registerHandler = (e) => {
        e.preventDefault();

        setHasFocussedField({...hasFocussedField, email: true, password: true});

        if (!isValid(validation)) {
            if (!validation.promocode) {
                setErrorCode("empty-promocode");
            }else {
                setErrorCode("form-missing-fields");
            }
            return;
        }

        setErrorCode(null);


        doAuthenticatedRequest("POST", "/security/redeem/amazon", {promoCode: user.promocode})
            .then(result => {
                setNavigate(true);
            })
            .catch(error => setErrorCode(error));
    };

    if (errorCode) {
        renderError = handleError(errorCode, null);
    }


    if (Navigate) {
        return window.location.replace("https://beatlii.com/pages/thank-you-amazon");
    }

    const handleTermsOfService = (input) => {
        setValidation({...validation, [input.target.name]: !validation.conditions});
    }

    // User input handling - Show error when they input wrong email format, password less than 8 char, or empty name. CTA button also being disable.
    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true});

        if (inputName === 'promocode') {
            var promoCode = inputValue.trim();
            setUser({...user, [inputName]: promoCode});
            if (promoCodeValidation(promoCode)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }
    };

    return <>
       <div className="redeem__contentBox">
           <div className="redeem__contentBox-header">
                <div className="redeem__containerBox-beatliiLogoContainer">
                    <FreedrumStudioLogo className="freedrumStudioLogo"/>
                    <AmazonLogo className="amazonLogo"/>
                </div>
           </div>

           <div className="redeem__containerBox">

                <h1 className="redeem__containerBox-title">Redeem promo code</h1>
                <p className="redeem__containerBox-text">Enter your promo code to redeem this exclusive trial to Beatlii account.</p>
                {renderError}
                <div className="redeem__containerBox_formBox">
                    <form className="RedeemScreen-form" autoComplete="off" onSubmit={registerHandler}>
                        <FormInput name='promocode' handleFormChange={handleFormChange} title="Promo Code"
                                    placeholder="Enter 6 or 8 digit number" type="text"
                                    valid={validation.promocode || !hasFocussedField.promocode} value={""} backgroundColor={"#f8f8f8"}/>
                        <div className="RedeemScreen-textBelowInputs-container">
                            <AiOutlineInfoCircle className="RedeemScreen-infoIcon"/>
                            <p className="RedeemScreen-textBelowInputs">Please enter the promo code sent to your email.</p>
                        </div>
                        <button type="submit" className="RedeemScreen-registerButton" id="register-button">
                            Redeem code
                        </button>
                    </form>
                </div>
            </div>
       </div>
    </>
}

export default RedeemScreenAmazon;