import "./thankyouscreen.scss"

import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

import {AiOutlineInfoCircle, AiOutlineCloseCircle, AiOutlineCheckCircle,} from "react-icons/ai";
import {doRequest} from "../../../api/requests";
import FreedrumStudioLogo from "../../../assets/images/freedrum-sequence.gif";
import ThankYouHero from "../../../assets/images/HeroImage-App.png";

import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";

import FormInput from "../../../components/forminput/FormInput";
import {handleError} from "../../../api/errorHandling";
import {emailValidation, nameValidation, passwordValidation, isValid} from "../../../components/forminput/validation";

import RegisterFooter from "../../../components/register/RegisterFooter";
import DeviceListsContainer from "../../../components/register/DeviceListContainer";
import PricingContainer from "../../../components/register/PricingContainer";
import SubscriptionInfoContainer from "../../../components/register/SubscriptionInfoContainer";
import FormContainer from "../../../components/register/FormContainer";

class ThankYouScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            validation: {name: false, email: false, password: false, conditions: false},
            hasFocussedField: {name: false, email: false, password: false, conditions: false},
            accountCreated: false,
        };

        document.title = "Thank you!";
        this.registerHandler = this.registerHandler.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleTermsOfService = this.handleTermsOfService.bind(this);
    }

    getTranslation(key) {
        const translations = {
            en: {
                thankYou: "Thank You For Signing Up!",
                downloadApp1: "Download the app and sign in with",
                downloadApp2: "to start using your subscription.",
                createAccount: "Create an Account to Get Started!",
                passwordRequirement: "Minimum 8 characters.",
                terms: "By signing up, you're agreeing to our",
                termsOfUse: "Terms of Use",
                privacyPolicy: "Privacy Policy",
                fullName: "Full Name",
                fullNamePlaceholder: "Your full name",
                email: "Email",
                password: "Password",
                and: "and",
                register: "Register",
                download: "Download Beatlii"
            },
            es: {
                thankYou: "¡Gracias por suscribirte!",
                downloadApp1: "Descarga la app e inicia sesión con",
                downloadApp2: "para empezar a usar tu suscripción.",
                createAccount: "¡Crea una cuenta para empezar!",
                passwordRequirement: "Mínimo 8 caracteres.",
                terms: "Al registrarte, aceptas nuestros",
                termsOfUse: "Términos de uso",
                privacyPolicy: "Política de privacidad",
                fullName: "Nombre completo",
                fullNamePlaceholder: "Tu nombre completo",
                email: "Correo electrónico",
                password: "Contraseña",
                and: "y",
                register: "Crear cuenta",
                download: "Descarga Beatlii"
            },
            pt: {
                thankYou: "Obrigado por assinar!",
                downloadApp1: "Baixe o app e faça login com",
                downloadApp2: "para começar a usar sua assinatura.",
                createAccount: "Crie uma conta para começar!",
                passwordRequirement: "Mínimo de 8 caracteres.",
                terms: "Ao se inscrever, você concorda com nossos",
                termsOfUse: "Termos de uso",
                privacyPolicy: "Política de privacidade",
                fullName: "Nome completo",
                fullNamePlaceholder: "Seu nome completo",
                email: "E-mail",
                password: "Senha",
                and: "e",
                register: "Criar conta",
                download: "Baixe Beatlii"
            },
            de: {
                thankYou: "Danke für dein Abonnement!",
                downloadApp1: "Lade die App runter und melde dich mit",
                downloadApp2: "an, um dein Abonnement zu nutzen.",
                createAccount: "Erstell ein Konto, um loszulegen!",
                passwordRequirement: "Mindestens 8 Zeichen.",
                terms: "Mit der Anmeldung stimmst du unseren",
                termsOfUse: "Nutzungsbedingungen",
                privacyPolicy: "Datenschutzerklärung",
                fullName: "Vollständiger Name",
                fullNamePlaceholder: "Dein Name",
                email: "E-Mail",
                password: "Passwort",
                and: "und",
                register: "Konto erstellen",
                download: "Beatlii herunterladen"
            }
        };

        const query = Object.fromEntries(new URLSearchParams(window.location.search).entries());
        const email = query["email"];
        const lang = query["lang"] ?? "en";

        return translations[lang][key];
    }

    componentDidMount() {
        const query = Object.fromEntries(new URLSearchParams(window.location.search).entries());
        const email = query["email"];

        if (email === undefined) {
            this.setState({
                accountExists: true,
            });
        } else {
            this.setState({
                user: {email: email},
                validation: {...this.state.validation, email: true},
                accountExists: false,
            });
        }
    }

    registerHandler(e) {
        e.preventDefault();

        this.setState({hasFocussedField: {...this.state.hasFocussedField, email: true, password: true}});

        if (!isValid(this.state.validation)) {
            if (!this.state.validation.email) {
                this.setState({errorCode: "email-address-invalid"});
            } else if (!this.state.validation.password) {
                this.setState({errorCode: "password-short"});
            } else if (!this.state.validation.conditions) {
                this.setState({errorCode: "accept-terms-missing"});
            } else {
                this.setState({errorCode: "form-missing-fields"});
            }
            return;
        }

        this.setState({errorCode: null});

        doRequest("POST", "/security/register", {email: this.state.user.email, password: this.state.user.password, name: this.state.user.name})
            .then(result => {
                ReactGA.event({
                    category: 'Account',
                    action: 'Created account',
                });
                this.setState({accountExists: true});
            })
            .catch(error => this.setState({errorCode: error}));
    };

    // User input handling - Show error when they input wrong email format, password less than 8 char, or empty name. CTA button also being disable.
    handleFormChange(input) {
        let inputName = input.name
        let inputValue = input.value;

        this.setState({errorCode: null, hasFocussedField: {...this.state.hasFocussedField, [inputName]: true}});

        if (inputName === 'name') {
            this.setState({user: {...this.state.user, [inputName]: inputValue}});
            if (nameValidation(inputValue)) {
                this.setState({validation: {...this.state.validation, [inputName]: true}});
            } else {
                this.setState({validation: {...this.state.validation, [inputName]: false}});
            }
        }

        if (inputName === 'password') {
            this.setState({user: {...this.state.user, [inputName]: inputValue}});
            if (passwordValidation(inputValue)) {
                this.setState({validation: {...this.state.validation, [inputName]: true}});
            } else {
                this.setState({validation: {...this.state.validation, [inputName]: false}});
            }
        }
    };

    handleTermsOfService(input) {
        this.setState({validation: {...this.state.validation, [input.target.name]: !this.state.validation.conditions}});
    }
    render() {
        let renderError;
        if (this.state.errorCode) {
            renderError = handleError(this.state.errorCode, null);
        }
        if (this.state.accountExists) {
            return <>
            <div className="thank-you-container">
                <div className="thank-you-image">
                    <img src={ThankYouHero} width="240px" alt="Thank You" />
                </div>

                <h1 className="thank-you-title">{this.getTranslation('thankYou')}</h1>
                <p className="thank-you-description">{this.getTranslation('downloadApp1')} {this.state.user.email} {this.getTranslation('downloadApp2')}</p>
            </div>
            <div className="RegisterScreenUpdate-container">
                <DeviceListsContainer text={this.getTranslation('download')} />
                <RegisterFooter />
            </div>
            </>
        } else {
            return <>
            <div className="thank-you-container">
                <div className="thank-you-image">
                    <img src={ThankYouHero} width="240px" alt="Thank You" />
                </div>

                <h1 className="thank-you-title">{this.getTranslation('createAccount')}</h1>
            </div>
            <form className="thank-you-form" autoComplete="off" onSubmit={this.registerHandler}>
                {renderError}

                <FormInput name='name' handleFormChange={this.handleFormChange} title={this.getTranslation('fullName')}
                    placeholder={this.getTranslation('fullNamePlaceholder')} type="text"
                    valid={this.state.validation.name || !this.state.hasFocussedField.name} value={this.state.user.name}/>
                <FormInput name='email' handleFormChange={this.handleFormChange} title={this.getTranslation('email')} type="email"
                    valid={true} value={this.state.user.email} disabledStatus={true} />
                <FormInput name='password' handleFormChange={this.handleFormChange} title={this.getTranslation('password')}
                    placeholder={this.getTranslation('passwordRequirement')} type="password"
                    valid={this.state.validation.password || !this.state.hasFocussedField.password} value=""/>
                <p className="thank-you-textBelowInputs password">{this.state.validation.password ?
                   <AiOutlineCheckCircle className="thank-you-wrongPasswordIcon" color="green"/> :
                   <AiOutlineCloseCircle className="thank-you-wrongPasswordIcon"/>
                }
                   {this.getTranslation('passwordRequirement')}
                </p>
                <div className="thank-you-termsAndConditions">
                   <label className="thank-you-termsAndConditions__label">
                       <input type="checkbox" name="conditions" checked={this.state.validation.conditions}
                              onChange={this.handleTermsOfService} id="terms_checkbox"/>
                       <div className="checkbox-custom"/>
                       <p htmlFor="terms_checkbox">
                           {this.getTranslation('terms')}
                           <span className="thank-you-termsAndConditions__textLink">
                               <Link to={{pathname: "https://beatlii.com/pages/terms-and-conditions"}}
                                     target="_blank"> {this.getTranslation('termsOfUse')}</Link></span> {this.getTranslation('and')} <span className="thank-you-termsAndConditions__textLink">
                               <Link to={{pathname: "https://beatlii.com/pages/privacy-notice"}} target="_blank"> {this.getTranslation('privacyPolicy')}</Link>
                           </span>.
                       </p>
                   </label>
               </div>
               <button type="submit" className="thank-you-registerButton" id="register-button">
                   {this.getTranslation('register')}
               </button>
            </form>
            <div className="RegisterScreenUpdate-container">
                <DeviceListsContainer text={this.getTranslation('download')} />
                <RegisterFooter />
            </div>

        </>
        }
    }
}

export default ThankYouScreen;