import "./registerscreenamazon.scss";
import {Link} from "react-router-dom";
import {useState, useEffect} from "react";
import {AiOutlineInfoCircle, AiOutlineCloseCircle, AiOutlineCheckCircle,} from "react-icons/ai";
import {Navigate} from "react-router";
import ReactGA from "react-ga4";

import {doRequest} from "../../../api/requests";
import {handleError, handleErrorGerman} from "../../../api/errorHandling";
import FormInput from "../../../components/forminput/FormInput";
import {emailValidation, nameValidation, passwordValidation, promoCodeValidation, isValid} from "../../../components/forminput/validation";
import {ReactComponent as FreedrumStudioLogo} from "../../../assets/images/Logo_FD_Studio.svg";
import {ReactComponent as AmazonLogo} from "../../../assets/images/AmazonLogo.svg";

import VerifyEmailModal from "./VerifyEmailModal";

const RegisterScreenAmazon = (args) => {

    const [user, setUser] = useState({name: '', email: '', password: '', promocode: ''});
    const [validation, setValidation] = useState({name: false, email: false, password: false, promocode: false, conditions: false})
    const [hasFocussedField, setHasFocussedField] = useState({
        name: false,
        email: false,
        password: false,
        conditions: false,
        promocode: false,
    });
    const [promotionActive, setPromotionActive] = useState(false)

    const [modalIsOpen, setIsOpen] = useState(false);

    const [errorCode, setErrorCode] = useState(null);
    const [Navigate, setNavigate] = useState(false);

    let renderError;

    const translations = {
        en: {
            title: "Create Account",
            subtitle: "Redeem your exclusive trial by creating a Beatlii account.",
            fullName: "Full name",
            email: "Email",
            emailPlaceholder: "youremail@domain.com",
            validEmail: "Please use a valid email address.",
            password: "Password",
            passwordHint: "Minimum 8 characters.",
            promocode: "Promo Code",
            promocodePlaceholder: "Enter 6 or 8 digit number",
            promocodeHint: "Please enter the promo code sent to your email.",
            terms: "By signing up, you're agreeing to our",
            termsLink: "Terms of Use",
            privacyLink: "Privacy Policy",
            createAccount: "Create Account",
            haveAccount: "Have an account?",
            login: "Login",
            thankYouPage: "https://beatlii.com/pages/thank-you-amazon",
            termsAndConditions: "https://beatlii.com/pages/terms-and-conditions",
            privacyNotice: "https://beatlii.com/pages/privacy-notice",
            pageTitle: "Beatlii - Create Account",
        },
        de: {
            title: "Konto erstellen",
            subtitle: "Löse deine exklusive Testversion ein, indem du ein Beatlii-Konto erstellst.",
            fullName: "Vollständiger Name",
            email: "E-Mail",
            emailPlaceholder: "deineemail@domain.com",
            validEmail: "Bitte gib eine gültige E-Mail-Adresse ein.",
            password: "Passwort",
            passwordHint: "Mindestens 8 Zeichen.",
            promocode: "Aktionscode",
            promocodePlaceholder: "6- oder 8-stellige Nummer eingeben",
            promocodeHint: "Bitte gib den per E-Mail gesendeten Aktionscode ein.",
            terms: "Mit deiner Anmeldung stimmst du unseren",
            termsLink: "Nutzungsbedingungen",
            privacyLink: "Datenschutzbestimmungen",
            createAccount: "Konto erstellen",
            haveAccount: "Hast du schon ein Konto?",
            login: "Anmelden",
            thankYouPage: "https://beatlii.com/de/pages/thank-you-amazon",
            termsAndConditions: "https://beatlii.com/de/pages/terms-and-conditions",
            privacyNotice: "https://beatlii.com/de/pages/privacy-notice",
            pageTitle: "Beatlii - Konto erstellen",
        }
    };

    useEffect(()=> {
        document.title = t.pageTitle;
    });

    const t = translations[args.language] || translations.en;

    // Function to send data to backend and activate modal when response is success.
    const registerHandler = (e) => {
        e.preventDefault();

        setHasFocussedField({...hasFocussedField, email: true, password: true});

        if (!isValid(validation)) {
            if (!validation.email) {
                setErrorCode("email-address-invalid");
            } else if (!validation.password) {
                setErrorCode("password-short");
            } else if (!validation.conditions) {
                setErrorCode("accept-terms-missing");
            } else if (!validation.promocode) {
                setErrorCode("empty-promocode");
            }else {
                setErrorCode("form-missing-fields");
            }
            return;
        }

        setErrorCode(null);
    

        doRequest("POST", "/security/register/amazon", {email: user.email, password: user.password, name: user.name, promoCode: user.promocode})
            .then(result => {
                ReactGA.event({
                    category: 'Account',
                    action: 'Created account',
                });
                if (!result.data.verified) {
                    user.email = result.data.email;
                    setIsOpen(true);
                }
            })
            .catch(error => setErrorCode(error));
    };

    if (errorCode) {
        if (args.language === 'de') {
            renderError = handleErrorGerman(errorCode, null);
        } else {
            renderError = handleError(errorCode, null);
        }
    }


    if (Navigate) {
        return window.location.replace(t.thankYouPage);
    }

    const handleTermsOfService = (input) => {
        setValidation({...validation, [input.target.name]: !validation.conditions});
    }

    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true});

        if (inputName === 'name') {
            setUser({...user, [inputName]: inputValue});
            if (nameValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'email') {
            setUser({...user, [inputName]: inputValue});
            if (emailValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'password') {
            setUser({...user, [inputName]: inputValue});
            if (passwordValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }

        if (inputName === 'promocode') {
            var promoCode = inputValue.trim();
            setUser({...user, [inputName]: promoCode});
            if (promoCodeValidation(promoCode)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }

            if (inputValue.length === 0) {
                setHasFocussedField({...hasFocussedField, [inputName]: false});
            }
        }
    };

    return <>
       <div className="register__contentBox">
           <div className="register__contentBox-header">
                <div className="register__containerBox-beatliiLogoContainer">
                    <FreedrumStudioLogo className="freedrumStudioLogo"/>
                    <AmazonLogo className="amazonLogo"/>
                </div>
           </div>
           
            <div className="register__containerBox">
                <h1 className="register__containerBox-title">{t.title}</h1>
                <p className="register__containerBox-text">{t.subtitle}</p>
                {renderError}
                <div className="register__containerBox_formBox">
                    <form className="RegisterScreen-form" autoComplete="off" onSubmit={registerHandler}>
                        <FormInput name='name' handleFormChange={handleFormChange} title={t.fullName}
                                    placeholder={t.fullName} type="text"
                                    valid={validation.name || !hasFocussedField.name} value={""} backgroundColor={"#f8f8f8"}/>
                        <FormInput name='email' handleFormChange={handleFormChange} title={t.email}
                                    placeholder={t.emailPlaceholder} type="email"
                                    valid={validation.email || !hasFocussedField.email}
                                    value={""} backgroundColor={"#f8f8f8"}/>
                        <div className="RegisterScreen-textBelowInputs-container">
                            <AiOutlineInfoCircle className="RegisterScreen-infoIcon"/>
                            <p className="RegisterScreen-textBelowInputs">{t.validEmail}</p>
                        </div>

                        <FormInput name='password' handleFormChange={handleFormChange} title={t.password}
                                    placeholder={t.password} type="password"
                                    valid={validation.password || !hasFocussedField.password} value={""} backgroundColor={"#f8f8f8"}/>

                        <p className="RegisterScreen-textBelowInputs password">
                            {validation.password ?
                            <AiOutlineCheckCircle className="RegisterScreen-wrongPasswordIcon" color="green"/> :
                            <AiOutlineInfoCircle className="RegisterScreen-wrongPasswordIcon" color="#696969"/>
                        }
                            {t.passwordHint}
                        </p>

                        <FormInput name='promocode' handleFormChange={handleFormChange} title={t.promocode}
                                    placeholder={t.promocodePlaceholder} type="text"
                                    valid={validation.promocode || !hasFocussedField.promocode} value={""} backgroundColor={"#f8f8f8"}/>

                        <div className="RegisterScreen-textBelowInputs-container">
                            <AiOutlineInfoCircle className="RegisterScreen-infoIcon"/>
                            <p className="RegisterScreen-textBelowInputs">{t.promocodeHint}</p>
                        </div>

                        <div className="RegisterScreen-termsAndConditions">
                            <label className="RegisterScreen-termsAndConditions__label">
                                <input type="checkbox"
                                        name="conditions"
                                        checked={validation.conditions}
                                        onChange={handleTermsOfService}
                                        id="terms_checkbox"
                                />
                                <div className="checkbox-custom"/>
                                <p htmlFor="terms_checkbox">
                                    {t.terms}
                                    <span className="RegisterScreen-termsAndConditions__textLink">
                                        <Link to={{pathname: t.termsAndConditions}}
                                            target="_blank"> {t.termsLink}</Link></span> and
                                    <span className="RegisterScreen-termsAndConditions__textLink"><Link
                                        to={{ pathname: t.privacyNotice }} target="_blank"> {t.privacyLink}</Link></span>.
                                </p>

                            </label>
                        </div>
                        <button type="submit" className="RegisterScreen-registerButton" id="register-button">
                            {t.createAccount}
                        </button>
                    </form>
                </div>

                <p className="RegisterScreen-askLogin">{t.haveAccount}
                        <span className="RegisterScreen-askLoginButton"
                        > <Link to="/amazon/login"> {t.login}</Link></span>
                </p>

            </div>
            {modalIsOpen && <VerifyEmailModal email={user.email} callback={() => setNavigate(true)} />}
       </div>
    </>
}

export default RegisterScreenAmazon;