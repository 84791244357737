import FormInput from "../forminput/FormInput";
import React, {useEffect, useState} from "react";
import {doAuthenticatedRequest} from "../../api/requests";
import {isValid, passwordValidation} from "../forminput/validation";
import {handleError} from "../../api/errorHandling";
import ChangePasswordModal from "../../pages/account/settings/profile/ChangePasswordModal";

export function ChangePasswordComponent({setDisabledStatus, email}) {
    const [validation, setValidation] = useState({previousPassword: false, password: false, repeatPassword: false});
    const [hasFocussedField, setHasFocussedField] = useState({password: false});
    const [errorCode, setErrorCode] = useState(null);
    const [passwordModel, setPasswordModel] = useState({previousPassword: '', password: '', repeatPassword: ''});
    const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState("verify");
    const [vid, setVid] = useState("");

    let renderError;

    useEffect(() => {
        if (!passwordModalIsOpen) {
            setDisabledStatus(false);
        }
    }, [passwordModalIsOpen, setDisabledStatus])

    const changePassword = (e) => {
        e.preventDefault();

        if (!isValid(validation)) {
            setErrorCode("password-short")
            return;
        }

        if (passwordModel.password !== passwordModel.repeatPassword) {
            setErrorCode("wrong-password")
            return;
        }

        setErrorCode(null);

        doAuthenticatedRequest("POST", "/security/change-password-step1", {
            oldPassword: passwordModel.previousPassword,
            newPassword: passwordModel.password
        })
            .then(result => {
                setVid(result.data.vid);
                setDisabledStatus(true)
                setModalName("verify");
                setPasswordModalIsOpen(true);
            })
            .catch(error => setErrorCode(error))
    }

    const passwordFormHandler = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true})
        setPasswordModel({...passwordModel, [inputName]: inputValue});

        if (passwordValidation(inputValue)) {
            setValidation({...validation, [inputName]: true});
        } else {
            setValidation({...validation, [inputName]: false});
        }
    }

    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    return <>
        <p className="EditProfileScreen-title">Change Password</p>
        <p className="EditProfileScreen-info">
            You will need to access the email account currently associated with your Beatlii account in order to make
            this change.
        </p>
        <div style={{marginTop: "16px"}}>
            {renderError}
        </div>
        <form className="EditProfileScreen-form" onSubmit={changePassword}>
            <FormInput title="Current Password" name="previousPassword" handleFormChange={passwordFormHandler}
                       valid={validation.previousPassword || !hasFocussedField.previousPassword}
                       value={""} placeholder={"Enter current password"} type='password'/>
            <FormInput title="New Password" name="password" handleFormChange={passwordFormHandler}
                       type='password' valid={validation.password || !hasFocussedField.password}
                       value={""} placeholder={"Minimum 8 characters"}/>
            <FormInput title="Repeat New Password" name="repeatPassword" handleFormChange={passwordFormHandler}
                       type='password' valid={validation.repeatPassword || !hasFocussedField.repeatPassword}
                       value={""} placeholder={"Minimum 8 characters"}/>
            <button type="submit" className="EditProfileScreen-sendProfileButton">
                Change Password
            </button>
        </form>
        {passwordModalIsOpen &&
        <ChangePasswordModal openModal={setPasswordModalIsOpen} modalName={modalName} vid={vid}
                             setModalName={setModalName} setDisabledStatus={setDisabledStatus}
                             email={email} />}
    </>;
}