import "../../pages/auth/registerscreen/registerscreenwithinfo.scss";
import {AiOutlineInfoCircle, AiOutlineCloseCircle, AiOutlineCheckCircle,} from "react-icons/ai";
import {Link} from "react-router-dom";

import {ReactComponent as FreedrumLogo}                                 from "../../assets/images/freedrum-logo.svg";
import FormInput                                                        from "../forminput/FormInput";

const FormContainer = ({validation, 
                        handleFormChange, 
                        handleTermsOfService, 
                        hasFocussedField, 
                        registerHandler, 
                        renderError, 
                        focusRef,
    }) => {
    return (
        <div className="RegisterScreenUpdate-TopArea">
            <div className="RegisterScreenUpdate-TopArea__heroimage"></div>
            <div className="RegisterScreenUpdate-TopArea__header">
                <div className="RegisterScreenUpdate-TopArea__header-beatliiLogoContainer">
                    <a href="https://beatlii.com"><FreedrumLogo/></a>
                </div>
                <div className="RegisterScreenUpdate-TopArea__header-logIn">
                    <button className="RegisterScreenUpdate-TopArea__header-logIn-Button">
                        <Link to="/login">Log in</Link>
                    </button>
                </div>
            </div>
            <div className="RegisterScreenUpdate-TopArea__FormContainer">
                <div className="RegisterScreenUpdate-TopArea__FormContainer-box">
                    <h1 className="RegisterScreenUpdate-TopArea__FormContainer-box-title">Become the Drummer You Want to Be</h1>
                    <div className="register__containerBox-formBox">
                        <form className="RegisterScreen-form" autoComplete="off" onSubmit={registerHandler} >
                            {renderError}
                            <FormInput name='name' handleFormChange={handleFormChange} title="Full name"
                                        placeholder="Your full name" type="text"
                                        valid={validation.name || !hasFocussedField.name} value={""} backgroundColor={"#f8f8f8"} focusRef = {focusRef}
                                       />
                            <FormInput name='email' handleFormChange={handleFormChange} title="Email"
                                        placeholder={"youremail@domain.com"} type="email"
                                        valid={validation.email || !hasFocussedField.email}
                                        value={""} backgroundColor={"#f8f8f8"} focusRef = {focusRef}/>
                            <div className="RegisterScreen-textBelowInputs-container">{validation.email ? 
                                <AiOutlineInfoCircle className="RegisterScreen-infoIcon" /> : <AiOutlineInfoCircle className="RegisterScreen-infoIcon" /> }
                                {validation.email ? <p className="RegisterScreen-textBelowInputs" >Please use a valid email address.</p> : <p className="RegisterScreen-textBelowInputs" >Please use a valid email address.</p> }
                            </div>
                            <FormInput name='password' handleFormChange={handleFormChange} title="Password"
                                        placeholder="Password" type="password"
                                        valid={validation.password || !hasFocussedField.password} value={""} backgroundColor={"#f8f8f8"} focusRef = {focusRef}/>
                            <div className="RegisterScreen-textBelowInputs-container password">
                                {validation.password ? 
                                <AiOutlineCheckCircle className="RegisterScreen-infoIcon" color="green"/> :
                                <AiOutlineInfoCircle className="RegisterScreen-infoIcon"/> }
                            <p className="RegisterScreen-textBelowInputs">Minimum 8 characters.</p>
                            </div>
                            
                            <div className="RegisterScreen-termsAndConditions">
                                <label className="RegisterScreen-termsAndConditions__label">
                                    <input type="checkbox"
                                            name="conditions"
                                            checked={validation.conditions}
                                            onChange={handleTermsOfService}
                                            id="terms_checkbox"
                                    />
                                    <div className="checkbox-custom"/>
                                    <p htmlFor="terms_checkbox">
                                        By signing up, you're agreeing to our  
                                        <span className="RegisterScreen-termsAndConditions__textLink">
                                            <Link to={{pathname: "https://beatlii.com/pages/terms-and-conditions"}}
                                                    target="_blank"> Terms of Use</Link></span> and  
                                        <span className="RegisterScreen-termsAndConditions__textLink"><Link
                                            to={{pathname: "https://beatlii.com/pages/privacy-notice"}} target="_blank"> Privacy Policy</Link></span>.
                                    </p>

                                </label>
                            </div>
                            <button type="submit" className="RegisterScreen-registerButton" id="register-button">
                                Register
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FormContainer;