import "./deleteaccountsuccess.scss";
import {ReactComponent as FreedrumLogo} from '../../../../assets/images/freedrum-logo.svg';
import React from "react";

class DeleteAccountSuccess extends React.Component {

    componentDidMount() {
        document.title = "Account Deleted - Confirmation";
    }

    render() {
        return <>
            <div className="DeleteAccount-container">
                <div className="DeleteAccount-container-beatliiLogoContainer">
                    <FreedrumLogo/>
                </div>
                <div className="DeleteAccount-dialogBox-container">
                    <h1>Account Deleted</h1>
                    <p>Your account is now removed from our database. </p>

                    <a className="DeleteAccount-dialogBox-form-closeButton" href={"https://beatlii.com"}
                       target="_self"> Back to home</a>
                </div>
            </div>
        </>
    }
};

export default DeleteAccountSuccess;