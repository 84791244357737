import "./verifyemailmodal.scss";
import ReactDOM from "react-dom";
import PinField from "react-pin-field";
import {Navigate} from "react-router";
import React, {useState, useRef, useEffect} from "react";

import {doRequest}                      from "../../../api/requests";
import {handleError}                    from "../../../api/errorHandling";
import {resendCode, saveCredentials}    from "../../../api/security";
import SuccessBox                       from "../../../components/successbox/SuccessBox";

function VerifyEmailModal({email, callback}) {
    const [vid, setVid] = useState("");
    const [secretCode, setSecretCode] = useState("");
    const [errorCode, setErrorCode] = useState(null);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const focusRef = useRef(null);
    const [successStatus, setSuccessStatus] = useState(false);
    let renderSuccess;
    let renderError;

    useEffect(() => {
        let currentElement = focusRef.current[0];
        if (currentElement !== undefined) {
            currentElement.focus();
        }

        doRequest("POST", "/security/verify-email-step1", {email: email})
            .then(result => {
                setVid(result.data.vid);
            })
            .catch(error => {
                console.log("got error: ", error);
            });
    }, [email, focusRef]);

    const sendSecretCode = () => {

        if (secretCode.length !== 6) {
            setErrorCode("form-missing-fields");
            return;
        }

        doRequest("POST", "/security/verify-email-step2", {vid: vid, code: secretCode})
            .then(result => {
                saveCredentials(result.data);
                callback();
            })
            .catch(error => setErrorCode(error));
    };

    const goBackToRegister = () => {
        setShouldNavigate(true);
    };

    // Now use the imported Navigate component with the state variable
    if (shouldNavigate) {
        return <Navigate to="/register" />;
    }

    if (successStatus) {
        renderSuccess = <SuccessBox text={<p>Code has been sent again.</p>} setSuccessStatus={setSuccessStatus}/>
    }

    const resendCode = (vid) => {
        doRequest("POST", "/security/resend-verification", {vid: vid})
            .then(() => {
                setErrorCode(null);
                setSuccessStatus(true);
            })
            .catch((error) => {
                setErrorCode(error);
                setSuccessStatus(false);
            });
    }
    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    return ReactDOM.createPortal(
          <div className="modal-container">
              <div className="ReactModal__Content">
                  <div className="VerifyEmailModal-container">
                    <button
                        onClick={goBackToRegister}
                        className="VerifyEmailModal-closeButton"
                        aria-label="Close"
                    >
                        &times;
                    </button>

                      <h1 className="VerifyEmailModal-title">Verify email</h1>

                      <p className="VerifyEmailModal-body" style={{marginBottom: "16px"}}>
                          Enter the 6-digit code sent to <strong>{email}</strong>
                      </p>
                      <ul className="VerifyEmailModal-body" style={{marginBottom: "16px", paddingLeft: "20px"}}>
                          <li>Can't find it? Check your spam folder or click "Resend code" below.</li>
                          <li>Wrong email? Go back to use the correct address.</li>
                      </ul>
                      {renderError}
                      {renderSuccess}
                      <div className="VerifyEmailModal-code-container">
                          <PinField ref={focusRef} length={6} className="VerifyEmailModal-code" onChange={setSecretCode}>
                          </PinField>
                      </div>
                      <button onClick={sendSecretCode} type="button" className="VerifyEmailModal-verifyButton">
                          Verify
                      </button>
                      <button onClick={() => resendCode(vid)} type="button"
                              className="VerifyEmailModal-resendCodeButton">
                          Resend code
                      </button>
                  </div>
              </div>
          </div>, document.body
      );
}

export default VerifyEmailModal;
